@import "../website-v2/_variables.less";

.before-after-octopus {
	--svg-base-color: #68778D;
	--svg-error-color: red;

	display: flex;
	flex-direction: column;
	align-items: center;
	gap: var(--space-80);

	@media screen and (min-width: @screen-lg) {
		margin-bottom: -28rem;
	}

	@media screen and (max-width: @screen-lg) {
		gap: var(--space-40);
	}

	&__before,
	&__after {
		width: 100%;
		border-radius: 0.625rem;
		border: 0.125rem dashed var(--marketo-dropdown-hover);
		background: #FFF;
		box-shadow: 0px 4px 70px 0px rgba(0, 0, 0, 0.10);
		padding: var(--space-48) var(--space-24) var(--space-24);
		display: flex;
		flex-direction: column;
		gap: var(--space-48);


		&.is-active {
			.arrows {
				path {
					// &:nth-child(2) {
					// 	animation-delay: 1000ms;
					// }
					// &:nth-child(3) {
					// 	animation-delay: 2000ms;
					// }
				}
			}
		}

		.header {
			@media screen and (min-width: @screen-lg) {
				margin: var(--space-0) var(--space-48);
			}
		}

		.header__content {
			gap: var(--space-12);
		}

		.header__subheading,
		.header__subheading p,
		.paragraph,
		.paragraph p,
		.paragraph ul {
			color: var(--text-secondary);
			font-size: var(--h5);
			font-weight: 400;
			line-height: 150%;
		}

		.paragraph {
			display: none;
		}

		@media screen and (max-width: @screen-lg) {
			svg {
				display: none;
			}

			.paragraph {
				display: block;
			}
		}

		.arrow {
				stroke-dasharray: 1000;
				stroke-dashoffset: 1000;
				transition: stroke-dashoffset 5000ms ease-in-out;
		}
	}

	&__after {
		z-index: 2;
	}

	&__arrows {
		width: 50%;

		@media screen and (max-width: @screen-lg) {
			width: 100%;
		}

		.before-after-octopus__before-arrow,
		.before-after-octopus__after-arrow {
			&--1,
			&--2 {
				stroke-dasharray: 1000;
				stroke-dashoffset: 1000;
				transition: stroke-dashoffset 3000ms ease-in-out;
			}
		}
	}

	&__before {
		&.is-active {
			.error-files {
					animation: pulse 4000ms infinite;
			}

			.arrow {
				// animation: drawArrow 5000ms ease-in-out forwards;
        stroke-dashoffset: 0;
			}

			&~.before-after-octopus__arrows {

				.before-after-octopus__before-arrow,
				.before-after-octopus__before-text {
					opacity: 1;
				}

				.before-after-octopus__before-arrow {
					&--1,
					&--2 {
						stroke-dashoffset: 0;
					}
				}

				.before-after-octopus__after-arrow,
				.before-after-octopus__after-text {
					opacity: 0.4;
				}
			}
		}

		&:not(.is-active) {

			&~.before-after-octopus__arrows {
				.before-after-octopus__after-arrow {
					&--1,
					&--2 {
						stroke-dashoffset: 0;
					}
				}
			}
		}
	}

	&__after {
		&.is-active {
			.arrow {
				// animation: drawArrow 5000ms ease-in-out forwards;
        stroke-dashoffset: 0;
			}
		}
	}

	#icons-red-blink-files {
		path {
			fill: var(--svg-base-color);
			stroke: var(--svg-base-color);
		}
	}

	.before-after-octopus__before-arrow,
	.before-after-octopus__before-text {
		opacity: 0.4;
		transition: opacity ease-in-out 1000ms;
	}

	.before-after-octopus__after-arrow,
	.before-after-octopus__after-text {
		opacity: 1;
		transition: opacity ease-in-out 1000ms;
	}

	@keyframes pulse {
		0% {
			fill: var(--svg-base-color);
			stroke: var(--svg-base-color);
		}

		50% {
			fill: var(--svg-error-color);
			stroke: var(--svg-error-color);
		}

		100% {
			fill: var(--svg-base-color);
			stroke: var(--svg-base-color);
		}
	}

	// @keyframes drawArrow {
	// 	to {
	// 		stroke-dashoffset: 0;
	// 	}
	// }

	.layout .flex-container:has(&)+.flex-container {
		@media screen and (min-width: @screen-lg) {
			padding-top: calc(var(--wave-height) + 14rem);
		}
	}
}