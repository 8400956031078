:root {
  // Elevation
  --octo-shadow-large: 10px 10px 32px rgba(0, 0, 0, 0.08),
  5px 5px 16px rgba(0, 0, 0, 0.06), 1px 1px 3px rgba(0, 0, 0, 0.06);
  --octo-shadow-medium: 0 8px 16px 0 rgba(0, 0, 0, 0.08),
  0 6px 10px 0 rgba(0, 0, 0, 0.02), 0 1px 4px 0 rgba(0, 0, 0, 0.02);
  --octo-shadow-standard-box: 0 2px 6px 0 hsla(0, 0%, 0%, 0.2);
  --octo-shadow-standard-box-hover: 0 2px 6px 0 hsla(0, 0%, 0%, 0.4);

  // Transitions
  // TODO: Remove 'all' from transition
  --hover-transition-duration: 0.15s;
  --hover-transition-ease: ease;
  --hover-transition: var(--hover-transition-duration) all var(--hover-transition-ease) !important;


  // Rounded edges
  --octo-rounded-small: 3px;
  --octo-rounded-large: 10px;

  // Font sizes
  --octo-font-size-extra-large: 37px;
  --octo-font-size-normal: 18px;
  --octo-font-size-small: 16px;
  --octo-font-size-extra-small: 14px;
  --h1: 48px;
  --h2: 32px;
  --h3: 24px;
  --h4: 24px;
  --h5: 20px;
  --h6: 16px;

  // Spacing
  --spacer-0: 0;
  --spacer-1: 0.25rem;
  --spacer-2: 0.5rem;
  --spacer-3: 1rem;
  --spacer-4: 1.5rem;
  --spacer-5: 3rem;

  // Line-heights
  --octo-line-size-normal: 160%;

  --cyan: var(--cyan-300);
  --navy: var(--navy-500);
  --green: var(--green-200);
  --red: var(--red-100);
  --orange: var(--orange-100);
  --purple: var(--purple-100);
  --white: #ffffff;

  --octo-light-theme-color-text-normal: var(--navy-600);

  // Keyline
  --octo-keyline: var(--outline-secondary);
}

// Breakpoints
// These are less variables because CSS variables can't be used in media queries
@screen-xs: 490px;
@screen-sm: 576px;
@screen-md: 768px;
@screen-lg: 991px;
@screen-xl: 1200px;
@screen-xxl: 1400px;

